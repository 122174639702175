define("discourse/plugins/discourse-sift/admin/models/sift-mod", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    confirmFailed(post) {
      return (0, _ajax.ajax)("/admin/plugins/sift/mod/confirm_failed", {
        type: "POST",
        data: {
          post_id: post.get("id")
        }
      });
    },
    disagree(post, reason) {
      return (0, _ajax.ajax)("/admin/plugins/sift/mod/disagree", {
        type: "POST",
        data: {
          post_id: post.get("id"),
          reason
        }
      });
    },
    disagree_action(reason, post_id, moderator_id, extra_reason_remarks) {
      return (0, _ajax.ajax)("/admin/plugins/sift/mod/disagree_action", {
        type: "POST",
        data: {
          reason,
          post_id,
          moderator_id,
          extra_reason_remarks
        }
      });
    },
    disagreeOther(post, reason, otherReason) {
      return (0, _ajax.ajax)("/admin/plugins/sift/mod/disagree_other", {
        type: "POST",
        data: {
          post_id: post.get("id"),
          reason,
          other_reason: otherReason
        }
      });
    }
  };
});