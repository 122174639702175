define("discourse/plugins/discourse-sift/discourse/controllers/admin-plugins-sift", ["exports", "@ember/controller", "@ember/object/computed", "discourse/lib/ajax-error", "discourse/plugins/discourse-sift/admin/models/sift-queue"], function (_exports, _controller, _computed, _ajaxError, _siftQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    sortedPosts: (0, _computed.sort)("model", "postSorting"),
    postSorting: ["id:asc"],
    enabled: false,
    performingAction: false,
    actions: {
      refresh() {
        this.set("performingAction", true);
        _siftQueue.default.findAll().then(result => {
          this.set("stats", result.stats);
          this.set("model", result.posts);
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("performingAction", false);
        });
      },
      confirmFailedPost(post) {
        this.set("performingAction", true);
        _siftQueue.default.confirmFailed(post).then(() => {
          this.get("model").removeObject(post);
          this.incrementProperty("stats.confirmed_failed");
          this.decrementProperty("stats.requires_moderation");
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("performingAction", false);
        });
      },
      allowPost(post) {
        this.set("performingAction", true);
        _siftQueue.default.allow(post).then(() => {
          this.incrementProperty("stats.confirmed_passed");
          this.decrementProperty("stats.requires_moderation");
          this.get("model").removeObject(post);
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("performingAction", false);
        });
      },
      dismiss(post) {
        this.set("performingAction", true);
        _siftQueue.default.dismiss(post).then(() => {
          this.get("model").removeObject(post);
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("performingAction", false);
        });
      }
    }
  });
});