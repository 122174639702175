define("discourse/plugins/discourse-sift/discourse/components/admin-disagree-flag-dropdown", ["exports", "@ember/object", "@ember/service", "I18n", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-sift/admin/models/sift-mod"], function (_exports, _object, _service, _I18n, _dropdownSelectBox, _siftMod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    classNames: ["disagree-flag", "admin-disagree-flag-dropdown"],
    adminTools: (0, _service.inject)(),
    nameProperty: "label",
    isDisabled: false,
    headerIcon: "thumbs-o-down",
    computeHeaderContent() {
      let content = this._super(...arguments);
      content.name = `${_I18n.default.t("sift.actions.disagree.title")}...`;
      return content;
    },
    sendAndDisable(reason) {
      if (reason === "other") {
        this.send("disagree_other", reason);
      } else {
        this.send("disagree", reason);
        this.set("isDisabled", true);
      }
      if (this.onReportComplete) {
        this.onReportComplete(reason);
      }
    },
    computeContent() {
      const content = [];
      content.push({
        icon: "cog",
        id: "disagree-false-positive",
        action: () => this.sendAndDisable("false_positive"),
        label: _I18n.default.t("sift.actions.disagree_due_to_false_positive.title"),
        description: _I18n.default.t("sift.actions.disagree_due_to_false_positive.description")
      });
      content.push({
        icon: "far-image",
        id: "disagree-too-strict",
        action: () => this.sendAndDisable("too_strict"),
        label: _I18n.default.t("sift.actions.disagree_due_to_too_strict.title"),
        description: _I18n.default.t("sift.actions.disagree_due_to_too_strict.description")
      });
      content.push({
        icon: "far-edit",
        id: "disagree-user-edited",
        action: () => this.sendAndDisable("user_edited"),
        label: _I18n.default.t("sift.actions.disagree_due_to_user_edited.title"),
        description: _I18n.default.t("sift.actions.disagree_due_to_user_edited.description")
      });
      content.push({
        icon: "far-dot-circle",
        id: "disagree-other",
        action: () => this.sendAndDisable("other"),
        label: _I18n.default.t("sift.actions.disagree_due_to_other.title"),
        description: _I18n.default.t("sift.actions.disagree_due_to_other.description")
      });
      return content;
    },
    mutateValue(value) {
      const computedContentItem = this.get("computedContent").findBy("value", value);
      (0, _object.get)(computedContentItem, "originalContent.action")();
    },
    actions: {
      disagree(reason) {
        let flaggedPost = this.get("post");
        _siftMod.default.disagree(flaggedPost, reason);
      },
      disagree_other(reason) {
        let flaggedPost = this.get("post");

        // eslint-disable-next-line no-alert
        let otherReason = prompt(_I18n.default.t("sift.actions.disagree_due_to_other.prompt"), _I18n.default.t("sift.actions.disagree_due_to_other.default"));
        if (otherReason == null || otherReason === "") {
          // eslint-disable-next-line no-alert
          alert(_I18n.default.t("sift.actions.disagree_due_to_other.error"));
          this.set("isDisabled", false);
        } else {
          _siftMod.default.disagreeOther(flaggedPost, reason, otherReason);
          this.set("isDisabled", true);
        }
      }
    }
  });
});