define("discourse/plugins/discourse-sift/helpers/sift-high-risk", ["discourse-common/lib/helpers"], function (_helpers) {
  "use strict";

  //This helper class is checking if the given risk level should be considered high-risk
  // currently this means greater than or equal to a 4
  // TODO: Could be extended to allow a threshold to be passed in, or configured somewhere (settings?)

  // Threshold
  let boundaryRiskLevel = 4;
  (0, _helpers.registerHelper)("sift-high-risk", function (params) {
    let topicValue = parseInt(params[0], 10);
    return topicValue >= boundaryRiskLevel;
  });
});