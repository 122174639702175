define("discourse/plugins/discourse-sift/discourse/connectors/flagged-post-below-controls/sift-flag-queue", ["exports", "I18n", "discourse/plugins/discourse-sift/admin/models/sift-mod"], function (_exports, _I18n, _siftMod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isReportingEnabled", true);
      component.set("reportedReason", "");
    },
    actions: {
      confirmFailedPost(flaggedPost) {
        _siftMod.default.confirmFailed(flaggedPost);
        this.set("isReportingEnabled", false);
        this.set("reportedReason", _I18n.default.t("sift.actions.agree.title"));
        //internalReportingDone("Agree");
      },
      markReportingDone(reason) {
        this.set("isReportingEnabled", false);
        let reason_key = "sift.actions.disagree_due_to_" + reason + ".title";
        let reason_string = _I18n.default.t(reason_key);
        this.set("reportedReason", reason_string);
        //internalReportingDone(reason)
      }
    }
  };
});