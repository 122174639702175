define("discourse/plugins/discourse-sift/admin/models/sift-queue", ["exports", "discourse/lib/ajax", "discourse/models/post"], function (_exports, _ajax, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    confirmFailed(post) {
      return (0, _ajax.ajax)("/admin/plugins/sift/confirm_failed", {
        type: "POST",
        data: {
          post_id: post.get("id")
        }
      });
    },
    allow(post) {
      return (0, _ajax.ajax)("/admin/plugins/sift/allow", {
        type: "POST",
        data: {
          post_id: post.get("id")
        }
      });
    },
    dismiss(post) {
      return (0, _ajax.ajax)("/admin/plugins/sift/dismiss", {
        type: "POST",
        data: {
          post_id: post.get("id")
        }
      });
    },
    findAll() {
      return (0, _ajax.ajax)("/admin/plugins/sift/index.json").then(result => {
        result.posts = result.posts.map(p => _post.default.create(p));
        return result;
      });
    }
  };
});